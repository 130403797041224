<template>
	<div :class="`${bgColor} py-1 px-3 w-100 position-relative h-100`" style="min-height: 80">
		<div class="title fw-400">{{ label }}</div>
		<div v-if="keywords">
			<small class="blue--text" style="font-size: 0.925rem">{{ keywords }}</small>
		</div>
		<div class="d-flex align-items-center" v-if="totalCount || upCount">
			<h1 v-if="totalCount" class="mb-0">{{ totalCount }}</h1>
			<div class="d-flex align-items-center" v-if="upCount">
				<v-icon color="green">mdi-menu-up</v-icon><span>{{ upCount }}</span>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "ChartCard",
	props: {
		bgColor: {
			type: String,
			default: "grey lighten-3",
		},
		color: {
			type: String,
			default: "white",
		},
		progressColor: {
			type: String,
			default: "white",
		},
		label: {
			type: String,
			default: "Label",
		},
		totalCount: {
			type: Number,
			default: 0,
		},
		upCount: {
			type: Number,
			default: 0,
		},
		progressStatus: {
			type: Number,
			default: 0,
		},
		keywords: {
			type: String,
			default: null,
		},
		progress: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {};
	},
};
</script>
<style scoped></style>
